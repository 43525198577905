import { DndContext, rectIntersection } from "@dnd-kit/core";
import { useEffect, useState } from "react";
import { TaskColumn } from "../TaskColumn";
import { Plan } from "../../types/card";
import { ApiService } from "../../services/api.service";
import { PlanStatus, PlanStatusMap } from "../../pages/Plans/plan-status-map";

export default function TaskBoard() {
  const [formsSentItems, setFormsSentItems] = useState<Plan[]>([]);
  const [meetingBookedItems, setMeetingBookedItems] = useState<Plan[]>([]);
  const [waitingForInfoItems, setWaitingForInfoItems] = useState<Plan[]>([]);
  const [readyToApplyItems, setReadyToApplyItems] = useState<Plan[]>([]);
  const [applicationInProgressItems, setApplicationInProgressItems] = useState<Plan[]>([]);
  const [awaitingForApprovalItems, setAwaitingForApprovalItems] = useState<Plan[]>([]);
  const [pendencyUnderwritingItems, setPendencyUnderwritingItems] = useState<Plan[]>([]);
  const [waitingClientAcceptanceItems, setWaitingClientAcceptanceItems] = useState<Plan[]>([]);
  const [inIssuingItems, setInIssuingItems] = useState<Plan[]>([]);

  const apiService = new ApiService();

  const getActivePlans = async () => {
    try {
      const response = await apiService.get("/plans", {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      const data = response?.data;

      const newFormsSentItems: Plan[] = [];
      const newMeetingBookedItems: Plan[] = [];
      const newWaitingForInfoItems: Plan[] = [];
      const newReadyToApplyItems: Plan[] = [];
      const newApplicationInProgressItems: Plan[] = [];
      const newAwaitingForApprovalItems: Plan[] = [];
      const newPendencyUnderwritingItems: Plan[] = [];
      const newWaitingClientAcceptanceItems: Plan[] = [];
      const newInIssuingItems: Plan[] = [];

      for (const plan of data) {
        switch (plan.status) {
          case "Forms sent":
            newFormsSentItems.push(plan);
            break;
          case "Meeting booked":
            newMeetingBookedItems.push(plan);
            break;
          case "Waiting for info": 
            newWaitingForInfoItems.push(plan);
            break;
          case "Ready to apply": 
            newReadyToApplyItems.push(plan);
            break;
          case "Application in progress":
            newApplicationInProgressItems.push(plan);
            break;
          case "Awaiting for approval":
            newAwaitingForApprovalItems.push(plan);
            break;
          case "Pendency underwriting":
            newPendencyUnderwritingItems.push(plan);
            break;
          case "Waiting client acceptance": 
            newWaitingClientAcceptanceItems.push(plan);
            break;
          case "In issuing": 
            newInIssuingItems.push(plan);
            break;
        }
      }

      setFormsSentItems(newFormsSentItems);
      setMeetingBookedItems(newMeetingBookedItems);
      setWaitingForInfoItems(newWaitingForInfoItems);
      setReadyToApplyItems(newReadyToApplyItems);
      setApplicationInProgressItems(newApplicationInProgressItems);
      setAwaitingForApprovalItems(newAwaitingForApprovalItems);
      setPendencyUnderwritingItems(newPendencyUnderwritingItems);
      setWaitingClientAcceptanceItems(newWaitingClientAcceptanceItems);
      setInIssuingItems(newInIssuingItems);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    getActivePlans();
    const addIds = (items: Plan[]) => items.map((item, index) => ({ ...item, id: item.id || index }));    
  }, []);
  
  const handleDragEnd = async (event: any) => {
    const { over, active } = event;

    if (!over) {
      return;
    }

    const containerId = over.id;
    const { id, customerName, productName, status, createdAt, parent, planDetails } = active.data.current;

    if (containerId === parent) {
      return; 
    }

    const itemToMove = { id, customerName, productName, status, createdAt, planDetails };

    try {
      const sourceColumnState = getColumnState(parent);
      const updatedSourceColumn = sourceColumnState.filter(item => item.id !== id);
      updateColumnState(parent, updatedSourceColumn);

      const destinationColumnState = getColumnState(containerId);
      const updatedDestinationColumn = [...destinationColumnState, itemToMove];
      updateColumnState(containerId, updatedDestinationColumn);

      const destinationColumnId = over.id;
      
      if (isValidPlanStatus(destinationColumnId)) {
        const body = {
          status: PlanStatusMap[destinationColumnId]
        };

        const response = await apiService.patch("/plans/" + id, body, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      
        const data = response?.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  function isValidPlanStatus(status: string): status is PlanStatus {
    return Object.keys(PlanStatusMap).includes(status);
  }

  const getColumnState = (columnId: string) => {
    switch (columnId) {
      case "Forms sent": return formsSentItems;
      case "Meeting booked": return meetingBookedItems;
      case "Waiting for info": return waitingForInfoItems;
      case "Ready to apply": return readyToApplyItems;
      case "Application in progress": return applicationInProgressItems;
      case "Awaiting for approval": return awaitingForApprovalItems;
      case "Pendency underwriting": return pendencyUnderwritingItems;
      case "Waiting client acceptance": return waitingClientAcceptanceItems;
      case "In issuing": return inIssuingItems;
      default: return [];
    }
  };

  const updateColumnState = (columnId: string, newState: Plan[]) => {
    switch (columnId) {
      case "Forms sent": setFormsSentItems(newState); break;
      case "Meeting booked": setMeetingBookedItems(newState); break;
      case "Waiting for info": setWaitingForInfoItems(newState); break;
      case "Ready to apply": setReadyToApplyItems(newState); break;
      case "Application in progress": setApplicationInProgressItems(newState); break;
      case "Awaiting for approval": setAwaitingForApprovalItems(newState); break;
      case "Pendency underwriting": setPendencyUnderwritingItems(newState); break;
      case "Waiting client acceptance": setWaitingClientAcceptanceItems(newState); break;
      case "In issuing": setInIssuingItems(newState); break;
    }
  };

  return (
    <DndContext
      collisionDetection={rectIntersection}
      onDragEnd={handleDragEnd}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ display: 'flex', flex: 3, overflowX: 'auto', padding: '10px', margin: '0 -100px', marginRight: '100px' }}>
          <TaskColumn borderTop="#FCEC0E" title="Forms sent" items={formsSentItems} />
          <TaskColumn borderTop="#F39C12" title="Meeting booked" items={meetingBookedItems} />
          <TaskColumn borderTop="#EC7063" title="Waiting for info" items={waitingForInfoItems} />
          <TaskColumn borderTop="#C5095A" title="Ready to apply" items={readyToApplyItems} />
          <TaskColumn borderTop="#7C2580" title="Application in progress" items={applicationInProgressItems} />
          <TaskColumn borderTop="#33418F" title="Awaiting for approval" items={awaitingForApprovalItems} />
          <TaskColumn borderTop="#5DADE2" title="Pendency underwriting" items={pendencyUnderwritingItems} />
          <TaskColumn borderTop="#58D68D" title="Waiting client acceptance" items={waitingClientAcceptanceItems} />
          <TaskColumn borderTop="#2C9647" title="In issuing" items={inIssuingItems} />
        </div>
      </div>
    </DndContext>
  );
}
