import React from 'react';
import { Box, Container, Divider, Paper, Stack, Typography, useMediaQuery, useTheme, Grid } from '@mui/material';
import Sidenav from '../../components/Sidenav';
import { BarChart, PieChart, useDrawingArea } from '@mui/x-charts';
import { styled } from '@mui/material/styles';

const Dashboard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const data = [
    { value: 5, label: 'A' },
    { value: 10, label: 'B' },
    { value: 15, label: 'C' },
    { value: 20, label: 'D' },
  ];

  const size = {
    width: isSmallScreen ? 300 : 500,
    height: 400,
  };

  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
  }));

  function PieCenterLabel({ children }: { children: React.ReactNode }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }

  const chartSetting = {
    width: isSmallScreen ? 350 : 500,
    height: 400,
  };

  return (
    <>
      <Sidenav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 10,
          bgcolor: '#F0F1F5'
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={1}>
            <Typography 
              variant="h5"
              sx={{
                color: '#003D6E',
                paddingBottom: isSmallScreen ? '10px' : '0px'
              }}
            >
              Dashboard
            </Typography>
            <Grid 
              container 
              justifyContent="center" 
              sx={{ 
                paddingTop: '20px',
                paddingBottom: isSmallScreen ? '20px' : '0px'
              }}
            >
              <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: isSmallScreen ? '20px' : '0px' }}>
                <Paper 
                  sx={{ 
                    p: 2, 
                    bgcolor: 'white', 
                    width: '100%',
                    height: isSmallScreen ? 'auto' : '500px',
                  }}
                >
                  Opportunity Status
                  <PieChart series={[{ data, innerRadius: 80 }]} {...size}>
                    <PieCenterLabel>Center label</PieCenterLabel>
                  </PieChart>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ marginBottom: isSmallScreen ? '20px' : '0px' }}>
                <Paper 
                  sx={{ 
                    p: 2, 
                    bgcolor: 'white', 
                    width: '100%',
                    height: isSmallScreen ? 'auto' : '500px',
                  }}
                >
                  Opportunity Value
                  <BarChart
                    series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
                    layout="horizontal"
                    {...chartSetting}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Paper 
                  sx={{ 
                    p: 2, 
                    bgcolor: 'white', 
                    width: '100%',
                    height: isSmallScreen ? 'auto' : '500px',
                  }}
                >
                  Conversion Rate
                  <PieChart series={[{ data, innerRadius: 80 }]} {...size}>
                    <PieCenterLabel>Center label</PieCenterLabel>
                  </PieChart>
                </Paper>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
