import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  IconButton, 
  Modal, 
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ArticleIcon from '@mui/icons-material/Article';
import moment from 'moment';
import { useState } from 'react';

interface Order {
  id: number;
  customerName: string;
  agentName: string;
  customerEmail: string;
  orderNumber: number;
  status: number;
  createdAt: string;  
  itemsPlan: ItemPlan[];
  planDetails: PlanDetail[];
}

interface ItemPlan {
  productName: string;
  price: number;
  quantity: number;
}

interface PlanDetail {
  id: number;
  notes: string;
  user: string;
  createdAt: string;
}

export const MyOrdersTable = (props: any) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    refreshData
  } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const[open, setOpen] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order>({
    id: 0,
    customerName: '',
    agentName: '',
    customerEmail: '',
    orderNumber: 0,
    status: 0,
    createdAt: '',
    itemsPlan: [],
    planDetails: []
  });

  const handleOpen = (order: any) => {
    console.log(order);
    setSelectedOrder(order);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrder({
      id: 0,
      customerName: '',
      agentName: '',
      customerEmail: '',
      orderNumber: 0,
      status: 0,
      createdAt: '',
      itemsPlan: [],
      planDetails: []
    });
  };

  const calculateTotal = (items: ItemPlan[]) => {
    return items.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #FAFAFA',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Card>
      <Box sx={{ minWidth: isSmallScreen ? 400 : 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Number</b>
              </TableCell>
              { isSmallScreen ? null : (
                <>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Ordered at</b>
                  </TableCell>
                </>
              )}
              <TableCell>
                <b>Details</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((plan: any) => {
              return (
                <TableRow
                  hover
                  key={plan.id}
                >
                  <TableCell>
                    <Stack
                      alignItems="center"
                      direction="row"
                      spacing={2}
                    >                        
                      <Typography variant="subtitle2">
                        {plan.orderNumber}
                      </Typography>
                    </Stack>
                  </TableCell>
                  { isSmallScreen ? null : (
                    <>
                      <TableCell>
                        {plan.status}
                      </TableCell>
                      <TableCell>
                        {moment(plan.createdAt).format('MMMM Do YYYY, h:mm a')}
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    <Tooltip title="Show Order Details">
                      <IconButton>
                        <ArticleIcon onClick={() => { handleOpen(plan)}} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <Modal 
        open={open} 
        onClose={handleClose} 
        aria-labelledby="modal-modal-title" 
        aria-describedby="modal-modal-description"
        sx={(theme) => ({
          '& .MuiBox-root': {
            [theme.breakpoints.down('sm')]: {
              width: '350px !important',
            },
            [theme.breakpoints.up('sm')]: {
              width: '500px !important',
            },
          },
        })}
      >
        <Box sx={{ ...style }}>
          <Typography 
            id="modal-modal-title" 
            variant="h6" 
            component="h2"
            sx={{
              color: '#003D6E',
              minWidth: isSmallScreen ? 350 : 500
            }}>
            Order Details
          </Typography>
          <Typography sx={{ mt: 2, fontSize: '0.875rem' }}>
            <b>Order Number:</b> {selectedOrder.orderNumber}
          </Typography>
          <Typography sx={{ mt: 2, fontSize: '0.875rem' }}>
            <b>Agent:</b> {selectedOrder.agentName}
          </Typography>
          <Typography sx={{ mt: 2, fontSize: '0.875rem' }}>
            <b>Status:</b> {selectedOrder.status}
          </Typography>
          <Typography sx={{ mt: 2, fontSize: '0.875rem' }}>
            <b>Ordered at:</b> {moment(selectedOrder.createdAt).format('MMMM Do YYYY, h:mm a')}
          </Typography>
          <Typography sx={{ mt: 4, fontSize: '0.875rem' }}>
            <b>Products</b>
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Product</b>
                </TableCell>
                <TableCell>
                  <b>Price</b>
                </TableCell>
                <TableCell>
                  <b>Quantity</b>
                </TableCell>
                <TableCell>
                  <b>Amount</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedOrder.itemsPlan.map((itemPlan: any, key: number) => {
                return (
                  <TableRow
                    hover
                    key={key}
                  >
                    <TableCell>
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing={2}
                      >                        
                        <Typography variant="subtitle2">
                          {itemPlan.productName}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {itemPlan.price}
                    </TableCell>
                    <TableCell>
                      {itemPlan.quantity}
                    </TableCell>
                    <TableCell>
                      {itemPlan.price * itemPlan.quantity}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell><b>Total</b></TableCell>
                <TableCell>{calculateTotal(selectedOrder.itemsPlan)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Button 
            color="primary" 
            variant="contained" 
            onClick={handleClose} 
            sx={{ 
            mt: 4,
            backgroundColor: '#003D6E', '&:hover': { 
                backgroundColor: '#005F8C' 
            }
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Card>
  );
};

MyOrdersTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  refreshData: PropTypes.func
};