import { useDroppable } from "@dnd-kit/core";
import { Plan } from "../../types/card";
import { Task } from "../Task";

interface TaskColumnProps {
  borderTop: string;
  title: string;
  items: Plan[];
}

export function TaskColumn({ borderTop, title, items }: TaskColumnProps) {
  const { setNodeRef } = useDroppable({
    id: title,
  });

  return (
    <div style={{ 
      flex: 3, 
      padding: '15px', 
      minHeight: '200px', 
      minWidth: '360px', 
      backgroundColor: '#F4F4F4', 
      borderRadius: '8px', 
      borderTop: '5px solid ' + borderTop,
      borderBottom: '1px solid #DDD',
      borderLeft: '1px solid #DDD',
      borderRight: '1px solid #DDD',
      margin: '0 5px', 
      color: '#003D6E',
      fontSize: '12px'
    }}>
      <h2 style={{ fontWeight: 'bold' }}>{title}</h2>
      <div ref={setNodeRef} style={{ 
        backgroundColor: '#F4F4F4', 
        borderRadius: '8px', 
        padding: '5px', 
        height: '70%',
        flex: 1 
      }}>
        { items.map((item, key) => (
          <Task 
            id={item.id} 
            customerName={item.customerName} 
            productName={item.productName} 
            status={item.status} 
            createdAt={item.createdAt} 
            planDetails={item.planDetails}
            key={key} 
            index={key} 
            parent={title}
          />
        ))}
      </div>
    </div>
  );
}
