import { useEffect, useState } from 'react';
import { Box, Button, Container, Input, Modal, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress } from '@mui/joy';
import { ProductsTable } from '../../sections/products';
import Sidenav from '../../components/Sidenav';
import { ApiService } from '../../services/api.service';

const apiService = new ApiService();

const Products = () => {
  const now = new Date();

  const [data, setData] = useState([]);
  const [updateProducts, setUpdateProducts] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  
  const [product, setProduct] = useState({
    name: "",
    description: ""
  });

  const handleChange = (event: any) => {
    const value = event.target.value;
    
    setProduct({
      ...product,
      [event.target.name]: value
    });
  };

  const fetchProducts = async () => {
    setIsLoading(true);

    try {
      const response = await apiService.get("/products", {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const createProduct = async () => {
    try {
      setUpdateProducts(0);

      const response = await apiService.post("/products", product, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      setUpdateProducts(response?.status ?? 0);
      
      setProduct({
        name:"",
        description: ""
      });

      handleClose();
      fetchProducts();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [updateProducts]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #FAFAFA',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      {
        isLoading ? (
          <>
            <Sidenav />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 10, 
                bgcolor: '#F0F1F5'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <CircularProgress />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Sidenav />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 10,
                bgcolor: '#F0F1F5'
              }}
            >
              <Container maxWidth="xl">
                <Stack spacing={3}>
                  <Stack direction="row" justifyContent="space-between" spacing={4}>
                    <Stack spacing={1}>
                      <Typography 
                        variant="h5"
                        sx={{
                          color: '#003D6E'
                        }}
                      >
                        Products
                      </Typography>
                      <Stack alignItems="center" direction="row" spacing={1}>
                      </Stack>
                    </Stack>
                    <div>
                      <Button 
                        onClick={handleOpen} 
                        startIcon={<AddIcon />} 
                        variant="contained"
                        sx={{
                          backgroundColor: '#003D6E',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#005F8C',
                          },
                        }}
                      >
                        Add
                      </Button>
                    </div>
                  </Stack>
                  { data && data.length > 0 ? (
                    <ProductsTable
                      count={data.length}
                      items={data}
                      refreshData={fetchProducts}
                    />
                  ) : (
                    <Typography>No products found</Typography>
                  )}
                </Stack>
              </Container>
            </Box>
          </>
        )
      }
      <Modal 
        open={open} 
        onClose={handleClose} 
        aria-labelledby="modal-modal-title" 
        aria-describedby="modal-modal-description"
        sx={(theme) => ({
          '& .MuiBox-root': {
            [theme.breakpoints.down('sm')]: {
              width: '350px !important',
            },
            [theme.breakpoints.up('sm')]: {
              width: '500px !important',
            },
          },
        })}
      >
        <Box sx={{ ...style }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Product
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <Input 
              type="text" 
              placeholder="Name" 
              name="name" 
              fullWidth={true} 
              value={product.name} 
              onChange={handleChange} 
              slotProps={{ 
                input: { 
                  className: 'textarea' 
                }
              }} 
            />
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <Input 
              placeholder="Description" 
              name="description" 
              fullWidth={true} 
              multiline={true} 
              defaultValue={product.description} 
              onChange={handleChange} 
              sx={{ 
                marginBottom: '30px'
              }}
            />
          </Typography>
          <Button 
            color="primary" 
            variant="outlined" 
            onClick={handleClose} 
            sx={{ 
              mt: 2, 
              marginRight: 1, 
              borderColor: '#005F8C', 
              color: '#005F8C', 
            }}
          >
            Cancel
          </Button>
          <Button 
            color="primary" 
            variant="contained" 
            onClick={createProduct} 
            sx={{ 
              mt: 2,
              backgroundColor: '#003D6E', '&:hover': { 
                backgroundColor: '#005F8C' 
              }
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

Products.getLayout = (page: any) => (
  //<DashboardLayout>
    {page}
  //</DashboardLayout>
);

export default Products;