import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Container, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import DoneIcon from '@mui/icons-material/Done';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import CircularProgress from '@mui/joy/CircularProgress';
import Sidenav from "../../components/Sidenav";
import { ApiService } from "../../services/api.service";
import { PendingActions } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";

const apiService = new ApiService();

interface ApplicationData {
  formName?: string;
  sections: Section[];
}

interface Section {
  uuid: string;
  name: string;
  description: string;
  isHidden: boolean;
  questions: Question[];
  nextSection: string;
}

interface Question {
  id: number;
  name: string;
  question: string;
  questionType: string;
  isRequired: boolean;
  hasConditionalSection: boolean;
  answers: Answer[];
  userAnswers: UserAnswer[];
}

interface Answer {
  answer: string;
  associatedSection: string;
}

interface UserAnswer {
  id: number;
  answer: string;
}

const Application = () => {
  const { applicationId } = useParams<{applicationId: string}>();

  const [application, setApplication] = useState<ApplicationData | null>(null);
  const [visibleSections, setVisibleSections] = useState<{[key: string]: boolean}>({});
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState<number>(0);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const [previousSectionIndices, setPreviousSectionIndices] = useState<number[]>([]);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const progressPercentage = totalQuestions > 0 ? (answeredQuestions / totalQuestions) * 100 : 0;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const notify = (message: string) => toast(message);

  const getApplication = async () => {
    setIsLoading(true);

    try {
      const response = await apiService.get('/applications/' + applicationId, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      setApplication(response?.data);

      const visibility: {[key: string]: boolean} = {};

      response?.data.sections.forEach((section: Section) => {
        visibility[section.uuid] = !section.isHidden;
      });
      
      setVisibleSections(visibility);

      let total = 0;
      let answered = 0;

      response?.data.sections.forEach((section: Section) => {
        if (!section.isHidden) {
          section.questions.forEach((question: Question) => {
            total += 1;
            
            if (question.userAnswers.length > 0 && question.userAnswers[0].answer) {
              answered += 1;
            }
          });
        }
      });

      setTotalQuestions(total);
      setAnsweredQuestions(answered);
    } catch (error) {
      console.error("Error getting application data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getApplication();
  }, [applicationId]);

  useEffect(() => {
    if (application) {
      checkRequiredQuestions();
    }
  }, [application, currentSectionIndex]);

  const handleInputChange = (sectionUuid: string, questionId: number, value: string) => {
    if (!application) {
      return;
    }

    setApplication(prevApplication => {
      if (!prevApplication) {
        return null;
      }
    
      const updatedSections = prevApplication.sections.map(section =>
        section.uuid === sectionUuid
          ? {
            ...section,
            questions: section.questions.map(question =>
              question.id === questionId
                ? {
                  ...question,
                  userAnswers: [{ id: 0, answer: value }]
                }
                : question
            )
          }
          : section
      );
    
      return {
        ...prevApplication,
        sections: updatedSections
      };
    });

    checkRequiredQuestions();
  };

  const handleAnswerChange = (sectionUuid: string, questionId: number, answer: string) => {
    if (!application) {
      return;
    }

    setApplication(prevApplication => {
      if (!prevApplication) {
        return null;
      }
    
      const updatedSections = prevApplication.sections.map(section =>
        section.uuid === sectionUuid
          ? {
            ...section,
            questions: section.questions.map(question =>
              question.id === questionId
                ? {
                  ...question,
                  userAnswers: question.questionType === "multipleChoice"
                    ?
                      question.userAnswers.some(userAnswer => userAnswer.answer === answer)
                        ? question.userAnswers.filter(userAnswer => userAnswer.answer !== answer)
                        : [...question.userAnswers, { id: 0, answer }]
                    :
                      [{ id: 0, answer }]
                }
              : question
            )
          }
          : section
      );
    
      return {
        ...prevApplication,
        sections: updatedSections
      };
    });

    checkRequiredQuestions();
  };  
  
  const saveAnswers = async () => {
    setIsLoading(true);

    const body = {
      userAnswers: application?.sections.flatMap(section =>
        section.questions.map(question => ({
          questionId: question.id,
          userAnswers: question.userAnswers
        }))
      )
    };

    try {
      await apiService.patch("/applications/" + applicationId, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((data) => {
        if (data) {
          navigate("/applications");
        }
      });
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>, sectionUuid: string, questionId: number) => {
    const file = event.target.files?.[0];

    if (!application || !file) {
      return;
    }

    const validFileTypes = ['application/pdf', 'image/jpeg', 'image/png'];
  
    if (!validFileTypes.includes(file.type)) {
      notify('Please upload a valid file (JPG, PNG or PDF).');
      return;
    }

    const formData = new FormData();
    formData.append('applicationId', applicationId || '');
    formData.append('questionId', questionId.toString());
    formData.append('document', file);

    try {
      const response = await apiService.post('/documents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response?.data?.fileUrl) {
        const fileUrl = response.data.fileUrl;
        
        setApplication(prevApplication => {
          if (!prevApplication) {
            return null;
          }

          const updatedSections = prevApplication.sections.map(section =>
            section.uuid === sectionUuid
              ? {
                ...section,
                questions: section.questions.map(question =>
                  question.id === questionId
                    ? {
                      ...question,
                      userAnswers: [{ id: 0, answer: fileUrl }],
                    }
                    : question
                )
              }
              : section
          );

          return {
            ...prevApplication,
            sections: updatedSections
          };
        });

        checkRequiredQuestions();
        
        console.log('File uploaded successfully. URL:', fileUrl);
      } else {
        console.error('File upload failed: No file URL in response');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      if (event.target) {
        event.target.value = '';
      }
    }
  };

  const handleNext = () => {
    if (!application) {
      return;
    }

    const currentSection = application.sections[currentSectionIndex];

    // Initialize to an invalid index
    let nextIndex = -1;

    // 1. Check if current section has a defined nextSection
    if (currentSection.nextSection) {
      nextIndex = application.sections.findIndex(s => s.uuid === currentSection.nextSection);
    }

    // 2. If nextSection is not defined or not found, check for conditional navigation based on answers
    if (nextIndex === -1) {
      let nextSectionUuid = '';

      for (const question of currentSection.questions) {
        if (question.questionType == 'singleChoice') {
          for (const answer of question.answers) {
            if (answer.answer == question.userAnswers[0].answer) {
              nextSectionUuid = answer.associatedSection;
              break;
            }
          }
        }
      }
      
      if (nextSectionUuid != '') {
        nextIndex = application.sections.findIndex(s => s.uuid === nextSectionUuid);
      }
    }

    // 3. If neither nextSection nor conditional navigation applies, proceed to the next visible section
    if (nextIndex === -1) {
      nextIndex = currentSectionIndex;

      do {
        nextIndex += 1;
      } while (nextIndex < application.sections.length && !visibleSections[application.sections[nextIndex]?.uuid]);
    }

    if (nextIndex >= 0 && nextIndex < application.sections.length) { 
      setPreviousSectionIndices([...previousSectionIndices, currentSectionIndex]);
      setCurrentSectionIndex(nextIndex);
    }
  };
  
  const handleBack = () => {
    if (!application) {
      return;
    }
  
    if (previousSectionIndices.length > 0) {
      const prevIndex = previousSectionIndices.pop();
      
      if (prevIndex !== undefined) {
        setPreviousSectionIndices([...previousSectionIndices]);
        setCurrentSectionIndex(prevIndex);
      } else {
          // This shouldn't happen but is here for safety.
          console.warn("previousSectionIndices.pop() returned undefined. This shouldn't happen.");
      }
    } else {
      let prevIndex = currentSectionIndex;
        
      do {
        prevIndex -= 1;
      } while (prevIndex >= 0 && !visibleSections[application.sections[prevIndex]?.uuid]);
  
      if (prevIndex >= 0) {
        setCurrentSectionIndex(prevIndex);
      }
    }
  };
  
  const toggleSectionVisibility = () => {
    setIsSectionVisible(!isSectionVisible);
  };

  const checkRequiredQuestions = () => {
    if (!application || !application.sections[currentSectionIndex]) {
      setIsNextButtonDisabled(true);
      return;
    }

    const currentSection = application.sections[currentSectionIndex];
    const requiredQuestions = currentSection.questions.filter(q => q.isRequired);
    const allRequiredAnswered = requiredQuestions.every(q => q.userAnswers.length > 0 && q.userAnswers[0].answer);

    setIsNextButtonDisabled(!allRequiredAnswered);
  };
  
  const style = {
    borderLeft: '4px solid #003D6E',
    borderRadius: '3px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
    marginBottom: '20px',
    padding: '30px'
  }

  const formHeaderStyle = {
    backgroundColor: '#FFF',
    borderTop: '6px solid #003D6E',
    borderRadius: '3px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
    marginBottom: '20px',
    padding: '30px'
  }

  const boxStyle = {
    display: 'flex',
  }

  const alignLeftStyle = {
    flex: '1'
  }

  const textAreaStyle = {
    width: '70%',
    padding: '0.375rem 1.75rem 0.375rem 0.75rem',
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    borderRadius: '3px'
  }

  const titleStyle = {
    width: '70%',
    marginBottom: '25px'
  }

  const inputStyle = {
    width: '70%',
    marginBottom: '15px'
  }

  const labelStyle = {
    marginLeft: '5px',
    marginBottom: '5px'
  }

  const optionsStyle = {
    marginBottom: '15px'
  }

  const pageStyle = {
    width: '70%',
    paddingTop: '5px',
    marginLeft: '50px'
  }

  const pendingQuestions = {
    display: isSectionVisible ? 'block' : 'none',
    color: 'red',
    padding: '15px',
    fontSize: '15px',
    backgroundColor: '#FFF',
    borderTop: '6px solid #003D6E',
    borderRadius: '3px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
    marginBottom: '20px'
  }

  const pendingQuestionsItems = {
    padding: '0px 0px 10px 10px'
  }

  const progressContainer = {
    width: isSmallScreen ? '300px' : '700px',
    height: '15px',
    backgroundColor: '#FFF',
    borderRadius: '5px',
    overflow: 'hidden'
  }

  const progressTitle = {
    width: isSmallScreen ? '160px' : '100px',
  }
  
  const progressBar = {
    width: '80%',
    height: '100%',
    backgroundColor: '#4caf50',
    transition: 'width 0.3s ease'
  }
 
  const textFieldStyle = {
    marginBottom: '15px'
  }

  return (
    <>
      {
        isLoading ? (
          <>
            <Sidenav />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 10, 
                bgcolor: '#F0F1F5'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <CircularProgress />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Sidenav />
            <ToastContainer />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 10,
                bgcolor: '#F0F1F5'
              }}
            >
              <Container maxWidth="xl">          
              <Stack
                direction={isSmallScreen ? 'column' : 'row'}
                justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                spacing={isSmallScreen ? 2 : 4}
                alignItems={isSmallScreen ? 'flex-start' : 'center'}
              >
                <Stack spacing={1}>
                  <Typography 
                    variant="h5"
                    sx={{
                      color: '#003D6E'
                    }}
                  >
                    Application
                  </Typography>
                  <Stack alignItems="center" direction="row" spacing={1}></Stack>
                </Stack>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <Button
                    onClick={toggleSectionVisibility}
                    startIcon={<PendingActions />}
                    variant="contained"
                    sx={{
                      mt: 2,
                      marginBottom: isSmallScreen ? '10px' : '15px',
                      backgroundColor: '#003D6E',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#005F8C',
                      },
                      width: isSmallScreen ? '100%' : 'auto',
                    }}
                  >
                    {isSectionVisible ? 'Hide Pending answers' : 'Show Pending answers'}
                  </Button>
                  <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                    startIcon={<ArrowLeftIcon />}
                    variant="contained"
                    sx={{
                      mt: 2,
                      marginBottom: isSmallScreen ? '10px' : '15px',
                      marginLeft: isSmallScreen ? '0' : '10px',
                      backgroundColor: '#003D6E',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#005F8C',
                      },
                      width: isSmallScreen ? '40%' : 'auto',
                    }}
                  >
                    Back
                  </Button>
                </div>
              </Stack>
                <Stack style={pendingQuestions}>
                <Typography style={titleStyle}>Pending answers</Typography>
                  {
                    application?.sections.map((section) => {
                      if (!section.isHidden) {
                        const unansweredQuestions = section.questions.filter((question) => question.userAnswers.length === 0);
                        
                        if (unansweredQuestions.length > 0) {
                          return (
                            <div key={section.uuid}>
                              <Typography style={{ fontSize: '16px', padding: '0px 0px 10px 0px', fontWeight: 'bold' }}>
                                {section.name}
                              </Typography>
                              {
                                unansweredQuestions.map((question) => (
                                  <div key={question.id} style={pendingQuestionsItems}>
                                    {question.question}
                                  </div>
                                ))
                              }
                            </div>
                          );
                        }
                      }

                      return null;
                    })
                  }
                </Stack>
                {
                  application && Array.isArray(application.sections) && application.sections.length > 0 && (
                    <div key={application.sections[currentSectionIndex].uuid}>
                      <Stack style={formHeaderStyle}>
                        <Typography style={titleStyle}>{application.sections[currentSectionIndex].name}</Typography>
                        {
                          application.sections[currentSectionIndex].description != '' && <Typography style={titleStyle}>{application.sections[currentSectionIndex].description}</Typography>
                        }
                        <Stack>
                          {
                            application.sections[currentSectionIndex].questions.length > 0 ?
                              application.sections[currentSectionIndex].questions.map((question, questionIndex) => {
                                if (question.name != "") {
                                  return (
                                    <div style={style}>
                                      <div style={boxStyle}>
                                        <div key={question.name} style={alignLeftStyle}></div>                                  
                                      </div>
                                      <div>
                                        {
                                          !question.isRequired && <><Typography style={inputStyle}>{question.question}</Typography></>
                                        }
                                        {
                                          question.isRequired && <><Typography style={inputStyle}>{question.question}<span style={{ color: 'red' }}> *</span></Typography></>
                                        }
                                        {
                                          question.questionType == "shortAnswer" && 
                                          <>                                    
                                            {
                                              question.userAnswers.length > 0 ? 
                                                <TextField 
                                                  id="outlined-basic" 
                                                  variant="outlined" 
                                                  style={inputStyle} 
                                                  value={question.userAnswers[0].answer} 
                                                  onChange={(e) => handleInputChange(application.sections[currentSectionIndex].uuid, question.id, e.target.value)}
                                                /> 
                                              : 
                                                <TextField 
                                                  id="outlined-basic" 
                                                  variant="outlined" 
                                                  style={inputStyle} 
                                                  onChange={(e) => handleInputChange(application.sections[currentSectionIndex].uuid, question.id, e.target.value)}
                                                />
                                            }
                                            
                                          </>
                                        }
                                        {
                                          question.questionType == "paragraph" && 
                                          <TextareaAutosize 
                                            style={textAreaStyle} 
                                            minRows={5} 
                                            onChange={(e) => handleInputChange(application.sections[currentSectionIndex].uuid, question.id, e.target.value)}
                                          />
                                        }
                                        {
                                          question.questionType == "singleChoice" && 
                                          <div>
                                            <div style={optionsStyle}>
                                              {
                                                question.answers.length > 0 ?
                                                  question.answers.map((answer) => 
                                                    <div style={boxStyle}>
                                                      <div style={alignLeftStyle}>
                                                        {
                                                          question.userAnswers.length > 0 ?
                                                            question.userAnswers[0].answer == answer.answer ?
                                                              <input 
                                                                type="radio" 
                                                                name={`answer-${questionIndex}`} 
                                                                key={`answer-${questionIndex}`} 
                                                                value={answer.answer} 
                                                                checked={question.userAnswers[0]?.answer === answer.answer} 
                                                                onChange={() => handleAnswerChange(application.sections[currentSectionIndex].uuid, question.id, answer.answer)} 
                                                              />
                                                            :
                                                              <input 
                                                                type="radio" 
                                                                name={`answer-${questionIndex}`} 
                                                                key={`answer-${questionIndex}`} 
                                                                value={answer.answer} 
                                                                checked={question.userAnswers[0]?.answer === answer.answer} 
                                                                onChange={() => handleAnswerChange(application.sections[currentSectionIndex].uuid, question.id, answer.answer)} 
                                                              />
                                                          :
                                                            <input 
                                                              type="radio" 
                                                              name={`answer-${questionIndex}`} 
                                                              key={`answer-${questionIndex}`} 
                                                              onChange={() => handleAnswerChange(application.sections[currentSectionIndex].uuid, question.id, answer.answer)} 
                                                            />
                                                        }
                                                        <label style={labelStyle}>{answer.answer}</label>
                                                      </div>
                                                    </div>
                                                  )
                                                :
                                                  <div></div>
                                              }
                                            </div>
                                          </div>
                                        }
                                        {
                                          question.questionType === "multipleChoice" &&
                                          <div>
                                            <div style={optionsStyle}>
                                              {question.answers.map((answer, index) => (
                                                <div key={`answer-${index}`}>
                                                  <input 
                                                    type="checkbox" 
                                                    name={`answer-${questionIndex}`} 
                                                    value={answer.answer} 
                                                    checked={question.userAnswers.some(userAnswer => userAnswer.answer === answer.answer)}
                                                    onChange={() => handleAnswerChange(application.sections[currentSectionIndex].uuid, question.id, answer.answer)} 
                                                  />
                                                  <label style={labelStyle}>{answer.answer}</label>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        }
                                        {
                                          question.questionType == "file" && (
                                            <div>
                                              { question.userAnswers.length > 0 && question.userAnswers[0].answer && (
                                                <Typography style={inputStyle}>
                                                  Uploaded File: <a href={question.userAnswers[0].answer} target="_blank" rel="noopener noreferrer">{question.userAnswers[0].answer}</a>
                                                </Typography>
                                              )}
                                              <TextField
                                                type="file"
                                                onChange={(event) => handleFileChange(event as ChangeEvent<HTMLInputElement>, application.sections[currentSectionIndex].uuid, question.id)}
                                                style={inputStyle}
                                                inputProps={{ accept: ".pdf, .jpg, .jpeg, .png" }}
                                              />
                                            </div>
                                          )
                                        }
                                        {
                                          question.questionType == "date" && 
                                          <div>
                                            <TextField 
                                              type="date" 
                                              id="date" 
                                              value={question.userAnswers[0]?.answer} 
                                              style={textFieldStyle} 
                                              onChange={(e) => handleInputChange(application.sections[currentSectionIndex].uuid, question.id, e.target.value)}
                                            />
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  );
                                }
                              })
                            :
                              <div></div>
                          }
                        </Stack>
                      </Stack>
                    </div>
                  )
                }
                <Stack
                  direction={isSmallScreen ? 'column' : 'row'}
                  spacing={2}
                  sx={{ mt: 2, alignItems: 'center' }}
                >
                  {isSmallScreen && (
                    <Typography style={pageStyle} sx={{ mt: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography variant="body2" style={progressTitle}>Page {currentSectionIndex + 1} of {application ? application.sections.length : 0}</Typography>
                        <div style={progressContainer}>
                          <div style={{ ...progressBar, width: `${progressPercentage}%` }}></div>
                        </div>
                      </Box>
                    </Typography>
                  )}
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Button
                        onClick={handleBack}
                        variant="outlined"
                        disabled={currentSectionIndex === 0}
                      >
                        Back
                      </Button>
                      <Button
                        onClick={handleNext}
                        variant="outlined"
                        disabled={isNextButtonDisabled || currentSectionIndex === (application ? application.sections.length - 1 : -1)}
                      >
                        Next
                      </Button>
                      {!isSmallScreen && (
                        <Typography style={pageStyle}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography variant="body2" style={progressTitle}>Page {currentSectionIndex + 1} of {application ? application.sections.length : 0}</Typography>
                            <div style={progressContainer}>
                              <div style={{ ...progressBar, width: `${progressPercentage}%` }}></div>
                            </div>
                          </Box>
                        </Typography>
                      )}
                    </Stack>
                    <Button
                      onClick={() => saveAnswers()}
                      startIcon={<DoneIcon />}
                      variant="contained"
                      disabled={isLoading}
                      sx={{
                        backgroundColor: '#003D6E',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#005F8C',
                        },
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </Stack>
              </Container>
            </Box>
          </>
        )
      }
    </>
  );
}

export default Application;