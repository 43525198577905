import PropTypes from 'prop-types';
import { Box, Card, IconButton, Stack, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { ApiService } from '../services/api.service';
import Swal from 'sweetalert2';
import moment from 'moment';

const apiService = new ApiService();

export const ApplicationsTable = (props: any) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0
  } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const role = localStorage.getItem("role");

  const navigate = useNavigate();

  const editApplication = (applicationId: number) => {
    navigate(`/applications/${applicationId}`);
  };

  const deleteApplication = async (id: number) => {
    Swal.fire({
      title: "Are you sure you want to remove this application?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        apiService.delete("/applications/" + id).then(response => {
          if (response?.status == 200) {
            window.location.reload();
          }
        });
      }
    });
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #FAFAFA',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
              
  return (
    <Card>
      <Box sx={{ minWidth: isSmallScreen ? 400 : 800 }}>
        <Table>
          <TableHead>
            <TableRow>              
              <TableCell>
                Plan
              </TableCell>
              {(role == 'Admin' || role == 'Processor') && !isSmallScreen && (
                <TableCell>
                  Agent
                </TableCell>
              )}              
              { (role == 'Admin' || role == 'Processor' || role == 'Agent') && !isSmallScreen && (
              <TableCell>
                Customer
              </TableCell>
              )}
              { isSmallScreen ? null : (
                <>
                  <TableCell>
                    Created At
                  </TableCell>
                </>
              )}
              <TableCell>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((application: any) => {
              return (
                <TableRow
                  hover
                  key={application.id}
                >
                  <TableCell>
                    <Stack
                      alignItems="center"
                      direction="row"
                      spacing={2}
                    >
                      <Typography variant="subtitle2">
                        {application.form.name}
                      </Typography>
                    </Stack>
                  </TableCell>
                  { (role == 'Admin' || role == 'Processor') && !isSmallScreen && (
                    <TableCell>
                      {application.itemPlan.plan.agent.firstName + ' ' + application.itemPlan.plan.agent.lastName}
                    </TableCell>
                  )}
                  { (role == 'Admin' || role == 'Processor' || role == 'Agent') && !isSmallScreen && (
                    <TableCell>
                      {application.itemPlan.plan.customer.firstName + ' ' + application.itemPlan.plan.customer.lastName}
                    </TableCell>
                  )}
                  { isSmallScreen ? null : (
                    <>
                      <TableCell>
                        {moment(application.createdAt).format('MMMM Do YYYY, h:mm a')}
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    <IconButton>
                      <EditIcon onClick={() => editApplication(application.id)} />
                    </IconButton>
                    { role == 'Admin' && (
                      <IconButton onClick={() => deleteApplication(application.id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

ApplicationsTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number
};