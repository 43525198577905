import React, { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './style.css';
import { ApiService } from "../../services/api.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiService = new ApiService();

const ResetPassword = () => {
  const { token } = useParams();

  const [data, setData] = useState({
    password: "",
    confirmPassword: ""
  });

  const handleChange = (event: any) => {
    const value = event.target.value;
    
    setData({
      ...data,
      [event.target.name]: value
    });
  };

  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const userData = {
      password: data.password,
      confirmPassword: data.confirmPassword
    };

    if (data.password != data.confirmPassword) {
      notify('Password and confirm password don\'t match');

      return;
    }

    const body = {
      password: data.password
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/reset-password/${token}`, body);
    
      if (response.data.success == false) {
        notify(response.data.message);

        setData({
          password: "",
          confirmPassword: ""
        })

        navigate("/reset-password/:token");

        return;
      }

      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("role", response.data.role);

      const roles = await apiService.get("roles", {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      const userRoles = roles?.data;
      localStorage.setItem("roles", JSON.stringify(userRoles));

      if (localStorage.getItem("role") == 'Customer') {
        navigate("/applications");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error('ERROR: ' + JSON.stringify(error));
    }
  };

  const notify = (message: string) => toast(message);

  return (      
    <div className="login-page">
      <img src="/images/logo-login.png" className=""/>
      <div className="form">
        <form className="login-form" onSubmit={handleSubmit}>
          <input type="password" placeholder="Password" name="password" value={data.password} onChange={handleChange} />
          <input type="password" placeholder="Confirm Password" name="confirmPassword" value={data.confirmPassword} onChange={handleChange} />
          <button>Reset Password</button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
  
export default ResetPassword;