import { useEffect, useState } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { CircularProgress } from '@mui/joy';
import Sidenav from '../../components/Sidenav';
import { ApiService } from '../../services/api.service';
import { MyOrdersTable } from '../../sections/my-orders';

const apiService = new ApiService();

const MyOrders = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMyOrders = async () => {
    setIsLoading(true);

    try {
      const response = await apiService.get("/plans/my-orders", {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMyOrders();
  }, [currentPage, rowsPerPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseFloat(event.target.value);

    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #FAFAFA',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      {
        isLoading ? (
          <>
            <Sidenav />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 10, 
                bgcolor: '#F0F1F5'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <CircularProgress />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Sidenav />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 10,
                bgcolor: '#F0F1F5'
              }}
            >
              <Container maxWidth="xl">
                <Stack spacing={3}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={4}
                  >
                    <Stack spacing={1}>
                      <Typography 
                        variant="h5"
                        sx={{
                          color: '#003D6E'
                        }}
                      >
                        My Orders
                      </Typography>
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                      >
                      </Stack>
                    </Stack>
                  </Stack>
                  { data && data.length > 0 ? (
                    <MyOrdersTable
                      count={total}
                      items={data}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      page={currentPage}
                      rowsPerPage={rowsPerPage}
                      refreshData={fetchMyOrders}
                    />
                  ) : (
                    <Typography>No orders found</Typography>
                  )}
                </Stack>
              </Container>
            </Box>
          </>
        )
      }      
    </>
  );
};

MyOrders.getLayout = (page: any) => (
  //<DashboardLayout>
    {page}
  //</DashboardLayout>
);

export default MyOrders;