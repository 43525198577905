export type PlanStatus = 
  'Forms sent' | 
  'Meeting booked' | 
  'Waiting for info' | 
  'Ready to apply' | 
  'Application in progress' | 
  'Waiting for approval' | 
  'Pendency underwriting' | 
  'Waiting client acceptance' | 
  'In issuing';

export const PlanStatusMap = {
  'Forms sent': 1,
  'Meeting booked': 2,
  'Waiting for info': 3,
  'Ready to apply': 4,
  'Application in progress': 5,
  'Waiting for approval': 6,
  'Pendency underwriting': 7,
  'Waiting client acceptance': 8,
  'In issuing': 9
};