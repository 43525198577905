import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export class ApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL
    });

    this.axiosInstance.interceptors.request.use((config) => {
      const token = localStorage.getItem('accessToken');
      
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      
      return config;
    }, (error) => {
      return Promise.reject(error);
    });
  }

  async get(endpoint: string, config?: AxiosRequestConfig) {
    try {
      const response = await this.axiosInstance.get(endpoint, config);
      
      if (response) {
        return response;
      }
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  async post(endpoint: string, body: any, config?: AxiosRequestConfig) {
    try {
      const response = await this.axiosInstance.post(endpoint, body, config);
      
      if (response) {
        return response;
      }
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  async patch(endpoint: string, body: any, config?: AxiosRequestConfig) {
    try {
      const response = await this.axiosInstance.patch(endpoint, body, config);
      
      if (response) {
        return response;
      }
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  async delete(endpoint: string) {
    try {
      const response = await this.axiosInstance.delete(endpoint);
      
      if (response) {
        return response;
      }
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  private handleError(error: any) {
    console.error('Request error: ', error);
    
    if (error.response && error.response.status === 403) {
      return error.response;
    }
    
    throw error;
}
}