import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Box, Button, Container, Grid, MenuItem, Paper, Select, SelectChangeEvent, Stack, styled, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import Sidenav from '../../components/Sidenav';
import avatar from '../../../src/assets/images/avatar.png';
import { ApiService } from '../../services/api.service';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ProfileData {
  id: number,
  firstName: string,
  lastName: string,
  language: string,
  profilePictureUrl?: string;
}

const Profile = () => {
  const[isLoading, setIsLoading] = useState<boolean>(true);
  const[password, setPassword] = useState<string>('');
  const[confirmPassword, setConfirmPassword] = useState<string>('');
  const[profileData, setProfileData] = useState<ProfileData>({
    id: 0,
    firstName: '',
    lastName: '',
    language: '',
    profilePictureUrl: ''
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [localAvatar, setLocalAvatar] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const notify = (message: string) => toast(message);

  const apiService = new ApiService();

  const getProfileData = async () => {
    setIsLoading(true);

    try {
      const response = await apiService.get('/users/profile-data', {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response?.status == 403) {
        navigate('/dashboard');
      }

      setProfileData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const updateProfileData = async () => {
    setIsLoading(true);

    try {
      const body = {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        language: profileData.language
      }

      const response = await apiService.patch(`/users/${profileData.id}`, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response?.data.affected == 1) {
        notify('Profile updated successfully')
      }

      if (response?.status == 403) {
        
      }
    } catch (error) {
      console.error("Error updating profile data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const updatePassword = async () => {
    if (password != confirmPassword) {
      notify('Passwords don\'t match');
      return;
    }

    setIsLoading(true);

    try {
      const body = {
        password: password
      }

      const response = await apiService.patch(`/users/${profileData.id}/update-password`, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response?.data.affected == 1) {
        notify('Password updated successfully')
      }

      if (response?.status == 403) {
        
      }
    } catch (error) {
      console.error("Error updating password:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleAvatarUpload = async () => {
    if (!selectedFile) {
      return;
    }

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append('avatar', selectedFile);
      
      await apiService.patch(`/users/${profileData.id}/update-avatar`, formData, { 
        headers: { 
          'Content-Type': 'multipart/form-data' 
        } 
      });
      
      notify('Avatar updated successfully');
      
      getProfileData();
    } catch (error) {
      console.error('Error uploading avatar:', error);
      
      notify('Error uploading avatar');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProfileData();
  }, [])

  useEffect(() => {
    if (profileData.profilePictureUrl) {
      setLocalAvatar(profileData.profilePictureUrl);
    } else {
      setLocalAvatar(avatar);
    }
  }, [profileData])

  const handleProfileDataChange = (event: any) => {
    const value = event.target.value;
    
    setProfileData({
      ...profileData,
      [event.target.name]: value
    });
  };

  const handleChangePassword = (event: any) => {
    setPassword(event?.target.value);
  }

  const handleChangeConfirmPassword = (event: any) => {
    setConfirmPassword(event?.target.value);
  }

  const handleAvatarClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setLocalAvatar(reader.result);
        } else {
          setLocalAvatar(null);
        }
      };

      reader.readAsDataURL(file);
      setSelectedFile(file); //Store the file
    }
  };

  const handleDeleteClick = () => {
    setLocalAvatar(avatar); //Reset to default avatar
    setSelectedFile(null); //Clear the selected file
  };

  const inputStyle = {
    width: '100%',
    marginBottom: '15px'
  }

  const selectStyle = {
    width: '100%',
    height: '45px',
    color: '#495057',
    verticalAlign: 'middle',
    background: '#fff',
    borderRadius: '3px',
    marginBottom: '5px'
  }

  return (
    <>
      <Sidenav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 10,
          bgcolor: '#F0F1F5'
        }}
      >
        <ToastContainer />
        <Container maxWidth="xl" disableGutters>
          <Stack spacing={1} sx={{ paddingLeft: 0, paddingRight: 0, marginLeft: 0 }}>
            <Typography 
              variant="h5"
              sx={{
                color: '#003D6E',
                paddingLeft: isSmallScreen ? '15px' : '0px',
              }}
            >
              Profile
            </Typography>
            <Stack
              alignItems="center"
              direction="row"
              spacing={1}
              sx={{ 
                paddingTop: '20px',
                paddingLeft: isSmallScreen ? '15px' : '0px',
                paddingRight: isSmallScreen ? '15px' : '0px',
              }}
            >
              <Paper 
                sx={{ 
                  p: 2, 
                  flex: 1, 
                  bgcolor: 'white', 
                  height: isSmallScreen ? '250px' : '200px',
                  paddingRight: '30px',
                  paddingBottom: '30px' 
                }}
              >
                Profile Picture
                <br /><br />
                <Box 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', sm: 'row' }, 
                    alignItems: 'center'
                  }}
                >
                  <Avatar 
                    alt="Profile Picture" 
                    src={localAvatar || avatar} 
                    onClick={handleAvatarClick}
                    sx={{ width: 100, height: 100, marginBottom: { xs: 2, sm: 0 }, marginRight: { sm: 1 } }}
                  />
                  <Box 
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'row', sm: 'row' },
                      justifyContent: 'flex-start',
                      gap: 1,
                      flexWrap: 'wrap',
                      marginTop: { xs: 2, sm: 0 },
                    }}
                  >
                    <Button 
                      variant="contained"
                      sx={{ marginRight: 1 }}
                      disabled={selectedFile == null}
                      onClick={handleAvatarUpload}
                    >
                      Update Picture
                    </Button>
                    <Button 
                      variant="outlined" 
                      color="error"
                      disabled={selectedFile != null}
                      onClick={handleDeleteClick}
                    >
                      Delete Picture
                    </Button>
                  </Box>
                </Box>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </Paper>
            </Stack>
            { isSmallScreen &&
              <br></br>
            }
            <Grid 
              container 
              sx={{ 
                width: '100%', 
                paddingLeft: isSmallScreen ? '15px' : '0px',
                paddingRight: isSmallScreen ? '15px' : '0px',
              }}
            >
              <Grid item xs={12} sm={12} md={6} sx={{ paddingLeft: 0, paddingRight: 0, paddingTop: isSmallScreen ? '15px' : '0px', marginLeft: 0 }}>
                <Paper sx={{ p: 2, bgcolor: 'white' }}>
                  <Typography>First Name</Typography>
                  <TextField
                    id="outlined-basic"
                    name="firstName"
                    variant="outlined"
                    value={profileData.firstName}
                    style={inputStyle}
                    onChange={handleProfileDataChange}
                    placeholder={'First Name'}
                  />
                  <Typography>Last Name</Typography>
                  <TextField
                    id="outlined-basic"
                    name="lastName"
                    variant="outlined"
                    value={profileData.lastName}
                    style={inputStyle}
                    onChange={handleProfileDataChange}
                    placeholder={'Last Name'}
                  />
                  <Typography>Language</Typography>
                  <Select
                    labelId="plan-status-label"
                    id="plan-status-select"
                    name="language"
                    value={profileData.language}
                    style={selectStyle}
                    onChange={handleProfileDataChange}
                  >
                    <MenuItem key={'en'} value={'en'}>
                      {'English'}
                    </MenuItem>
                    <MenuItem key={'pt'} value={'pt'}>
                      {'Portuguese'}
                    </MenuItem>
                  </Select>
                  <Button
                    variant="contained"
                    onClick={() => updateProfileData()}
                    disabled={isLoading}
                  >
                    Update
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6} sx={{ paddingLeft: isSmallScreen ? '0px' : '20px', paddingRight: 0, paddingTop: isSmallScreen ? '15px' : '0px' }}>
                <Paper sx={{ p: 2, bgcolor: 'white' }}>
                  <Typography>New Password</Typography>
                  <TextField
                    id="password-field"
                    type="password"
                    variant="outlined"
                    style={inputStyle}
                    onChange={handleChangePassword}
                    placeholder={'New Password'}
                  />
                  <Typography>Confirm New Password</Typography>
                  <TextField
                    id="confirm-password-field"
                    type="password"
                    variant="outlined"
                    style={inputStyle}
                    onChange={handleChangeConfirmPassword}
                    placeholder={'Confirm New Password'}
                  />
                  <Button
                    variant="contained"
                    onClick={() => updatePassword()}
                    disabled={isLoading}
                  >
                    Update
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Profile;