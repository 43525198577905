import { Box, Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import Sidenav from '../../components/Sidenav';
import TaskBoard from '../../components/TaskBoard';

const ApplicationsBoard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Sidenav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 10,
          bgcolor: '#FFF',
          display: 'flex',
          height: '100vh',
        }}
      >
        <Container sx={{ flexGrow: 1, maxWidth: 'none', marginLeft: isSmallScreen ? '80px' : '150px', marginRight: isSmallScreen ? '0px' : '150px' }}>
          <Stack spacing={3} sx={{ height: '100%' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
              sx={{ 
                height: '100%',
              }}
            >
              <Box sx={{ flexGrow: 1, bgcolor: '#FFF', borderRadius: '8px', height: '100%' }}>
                <TaskBoard/>
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default ApplicationsBoard;
