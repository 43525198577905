import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { PlanDetail } from "../../types/card";
import { IconButton, Stack, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import avatar from '../../../src/assets/images/avatar.png';

interface TaskProps {
  id: number;
  customerName: string;
  productName: string;
  status: number;
  createdAt: string;
  index: number;
  parent: string;
  planDetails: PlanDetail[];
}

export const Task = ({ id, customerName, productName, status, createdAt, planDetails, index, parent }: TaskProps) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
    data: {
      id,
      customerName,
      productName,
      status,
      createdAt,
      planDetails,
      index,
      parent,
    },
  });

  const navigate = useNavigate();

  const editPlan = (planId: number) => {
    navigate(`/plan-details/${planId}`);
  };

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    height: '200px',
    width: '320px',
    padding: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    borderRadius: '8px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    flexWrap: "wrap",
    wordWrap: "break-word",
    wordBreak: "break-all",
    overflowY: 'auto'
  };

  return (
    <div style={style}>
      <IconButton>
        <EditIcon fontSize="small" onClick={() => editPlan(id)} />
      </IconButton>
      <div
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      > 
        <b>Customer: </b>{customerName}
        <br></br>
        <b>Product: </b>{productName}
        <br></br>
        <b>Date: </b>{createdAt}
        <br></br>
        <br></br>
        { (planDetails.length > 0) && 
          <hr color="#FAFAFA" />
        }
        <br></br>
        { planDetails.map((planDetail, detailIndex) => (
          <div key={detailIndex}>
            <Stack direction="row" spacing={1} alignItems="center">
              <img 
                src={avatar} 
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%'
                }} 
                alt="Avatar"
              />
              <Stack spacing={0}>
                <Typography variant="subtitle2">
                  { planDetail.user }
                </Typography>
                <Typography variant="subtitle2">
                  { new Date(planDetail.createdAt).toDateString() }
                </Typography>
              </Stack>
            </Stack>
            <br></br>
            <Typography variant="body2">
              { planDetail.notes }
            </Typography>
            <br></br>
            <hr color="#FAFAFA" />
            <br></br>
          </div>
        ))}
      </div>
    </div>
  );
};
